import React from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './cookies.scss';

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />        
        <div className={`${pageContext.slug} default-template`}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>Cookies</h1>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="content-block" dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <a href="#cookies" className="btn btn-black ch2-open-settings-btn">Manage Cookie Settings</a>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)